const root = "api/v1"

type Condition = 'equal' | 'notEqual' | 'lessThan' | 'greaterThan' | 'greaterThanOrEqual';
type Direction = 'asc' | 'desc';

interface Filter {
    member: string;
    condition: Condition;
    values: string[];
}

interface Order {
    member: string;
    direction: Direction;
}

interface Page {
    length: number;
    current: number;
    total: number;
}

interface Query<T> {
    id?: string;
    filters?: Filter[];
    members?: string[];
    distinct: boolean;
    order?: Order;
    page?: Page;
    count: number;
    data: T[];
}

interface Client {
    id?: string;
    label?: string;
    name: string;
    description?: string;
    grants: string[];
    scopes: string[];
    requireClientSecret: boolean,
    allowOfflineAccess: boolean,
    serial: string,
}

export const putClient = (url: string, token: string, request: Client): Promise<Client> => {
    return fetch(`${url}/${root}/clients`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
    })
        .then(response => {
            if (!response.ok)
                return Promise.reject(response);

            return response.json();
        });
}

export const getClients = (url: string, token: string, request: Query<Client>): Promise<Query<Client>> => {
    return fetch(`${url}/${root}/clients`, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(request)
    })
        .then(response => {
            if (!response.ok)
                return Promise.reject(response);

            return response.json();
        });
}
