import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const NotFound = () => {
    return (
        <div className="mb-4 card">
            <div className="card-body">
                <div className="pt-3 text-center">
                    <FontAwesomeIcon icon={faTimesCircle} size="5x" color="#ed443f" />
                    <p className="pt-3 font-weight-bold">Not Found</p>
                    <p>The resource requested was not found or has moved</p>
                </div>
            </div>
        </div>
    );
}
