import * as React from 'react';
import * as Informed from 'informed';
import { FormGroup } from './FormGroup';
import { useUniqueId } from '../Hooks/UniqueId';
import { Validator, noValidation } from '../Validators/Validator';
import { validateMany } from '../Validators/Many';
import { validateRequired } from '../Validators/Required';
import { validateUrl } from '../Validators/Url';
import strings from '../strings.json';

interface UrlProps {
    field: string;
    automationId?: string;
    initialValue?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    onValidate?: Validator;
    requiredMessage?: string;
    invalidFormatMessage?: string;
}

export const Url = ({ field, automationId, initialValue = '', label = '', placeholder = 'https://example.com', disabled = false, autoFocus = false, required = false, onValidate = noValidation, requiredMessage = strings.required, invalidFormatMessage = strings.invalidUrl }: UrlProps) => {

    const uniqueId = useUniqueId();
    const fieldState = Informed.useFieldState(field);

    const handleValidation = validateMany([
        validateRequired(required, requiredMessage),
        validateUrl(invalidFormatMessage),
        onValidate,
    ]);

    const errorMessage = fieldState.error
        ? (<div className="invalid-feedback">{fieldState.error}</div>)
        : null;

    const invalidClass = fieldState.error
        ? 'is-invalid'
        : '';

    const requiredClass = required
        ? 'is-required'
        : '';
 
    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <Informed.Text id={uniqueId} className={`form-control ${invalidClass} ${requiredClass}`} type="url" field={field} initialValue={initialValue} placeholder={placeholder} disabled={disabled} autoFocus={autoFocus} validate={handleValidation} autoComplete="off" data-automationid={automationId} />
            {errorMessage}
        </FormGroup>
    );
}
