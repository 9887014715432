import * as React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@vitalograph/web-ui';
import { useDeviceLinkDashboard } from '../../Hooks/DeviceLinkDashboard';

export const InstallerCard = () => {

    const { about } = useDeviceLinkDashboard();

    const header = (
        <h4 className="m-0 text-primary">Device Link Installer</h4>
    );

    const footer = (
        <a className="btn btn-primary" href={about.installerWin64Address}>Download</a>
    );

    return (
        <Card icon={faDownload} header={header} footer={footer}>
            <p>Download and install the latest version of Device Link.</p>
        </Card>
    );
}
