import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { TermsAndConditionsStep } from './TermsAndConditionsStep';
import { InstallationStep } from './InstallationStep';
import { UserDetailsStep } from './UserDetailsStep';
import { SystemDetailsStep } from './SystemDetailsStep';
import { ResultsStep } from './ResutlsStep';
import { SiteSurvey } from '../../Api/DashboardApi';
import { useParams } from 'react-router';

interface UrlParams {
    label: string;
}

export enum TroubleshootStep {
    TermsAndConditions,
    UserDetails,
    Installation,
    SystemDetails,
    Results,
}

export interface TroubleshootStepProps {
    visible: boolean;
    details: SiteSurvey;
    onChangeStep: (step: TroubleshootStep) => void;
    onDetailsChange: (details: SiteSurvey) => void;
}

export const Troubleshoot = () => {
    const [step, setStep] = useState<TroubleshootStep>(TroubleshootStep.TermsAndConditions);
    const { label } = useParams<UrlParams>();
    const [details, setDetails] = useState<SiteSurvey>({
        organization: '',
        address: '',
        siteNumber: '',
        countryId: 'GB',
        contactName: '',
        timeZoneId: '',
        operatingSystem: '',
        operatingSystemError: '',
        architecture: '',
        framework: '',
        browser: '',
        uploadSpeed: 0,
        downloadSpeed: 0,
    });

    useEffect(() => {
        setDetails({ ...details, label: label });
    }, [label]);

    return (
        <div className="mb-4 card">
            <div className="card-header bg-transparent">
                <h4 className="m-0 text-primary">Troubleshooter</h4>
            </div>
            <TermsAndConditionsStep visible={step === TroubleshootStep.TermsAndConditions} details={details} onChangeStep={setStep} onDetailsChange={setDetails} />
            <UserDetailsStep visible={step === TroubleshootStep.UserDetails} details={details} onChangeStep={setStep} onDetailsChange={setDetails} />
            <InstallationStep visible={step === TroubleshootStep.Installation} details={details} onChangeStep={setStep} onDetailsChange={setDetails} />
            <SystemDetailsStep visible={step === TroubleshootStep.SystemDetails} details={details} onChangeStep={setStep} onDetailsChange={setDetails} />
            <ResultsStep visible={step === TroubleshootStep.Results} details={details} onChangeStep={setStep} onDetailsChange={setDetails} />
        </div>
    );
}
