import * as React from 'react';

interface Unit {
    value: string;
    unit: 'b' | 'Kb' | 'Mb' | 'Gb' | 'Tb';
}

const format = (value: number): Unit => {
    if (value < 1024)
        return { value: value.toString(), unit: 'b' };

    value = value / 1024;

    if (value < 1024)
        return { value: value.toPrecision(3), unit: 'Kb' };

    value = value / 1024;

    if (value < 1024)
        return { value: value.toPrecision(3), unit: 'Mb' };

    value = value / 1024;

    if (value < 1024)
        return { value: value.toPrecision(3), unit: 'Gb' };

    value = value / 1024;

    return { value: value.toPrecision(3), unit: 'Tb' };
}

interface BitsProps {
    value: number;
    measurment?: '' | '/s';
}

export const Bits = ({ value, measurment = '' }: BitsProps) => {

    const formatted = format(value);

    return <span className="format format-bits">{formatted.value}&nbsp;<span className="text-muted text-weight-bold">{formatted.unit}{measurment}</span></span>;
}
