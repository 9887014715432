import * as React from 'react';
import { InstallerCard } from './InstallerCard';
import { TroubleshooterCard } from './TroubleshooterCard';
import { AboutCard } from './AboutCard';
import { DeviceCard } from './DeviceCard';
import { useDeviceLink } from '../../Hooks/DeviceLink';

export const Dashboard = () => {

    const deviceLink = useDeviceLink();

    const aboutCard = deviceLink.online
        ? (<AboutCard version={deviceLink.about.version} machineName={deviceLink.about.machineName} operatingSystem={deviceLink.about.operatingSystem} framework={deviceLink.about.framework} architecture={deviceLink.about.architecture} timeZone={deviceLink.about.timeZone} environment={deviceLink.about.environment} updates={deviceLink.about.isUpdatePending} utcOffset={deviceLink.about.utcOffset} />)
        : (<InstallerCard />);

    const deviceCards = deviceLink.devices.map(device => (<DeviceCard key={device.id} {...device} />));

    return (
        <>
            {aboutCard}
            {deviceCards}
            <TroubleshooterCard />
        </>
    );
}
