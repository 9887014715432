import * as React from 'react';
import { useState, ReactNode, useRef } from 'react';

interface DropDownProps {
    label: ReactNode;
    children?: ReactNode;
}

export const DropDown = ({ label, children }: DropDownProps) => {

    const [show, setShow] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const handleClick = () => setShow(!show);

    return (
        <div className={`nav-item dropdown${show ? ' show' : ''}`}>
            <a className="dropdown-toggle nav-link" onClick={handleClick}>{label}</a>
            <div ref={menuRef} className={`dropdown-menu${show ? ' show' : ''}`}>
                {children}
            </div>
        </div>
    );
}
