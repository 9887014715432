import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faPneumotrac: IconDefinition = {
    prefix: "fab",
    iconName: "edit",
    icon: [
        100,
        100,
        [],
        "0061",
        "M57.661,66.694L48.332,66.694C51.548,64.225 53.877,57.023 53.877,48.531C53.877,40.041 51.548,32.838 48.332,30.369L75.113,30.369C78.327,32.837 80.657,40.04 80.657,48.531C80.657,57.023 78.328,64.225 75.113,66.694L66.206,66.694L66.206,68.162C66.206,68.972 65.143,69.63 63.835,69.63C63.057,69.63 62.366,69.398 61.933,69.039C61.501,69.398 60.81,69.63 60.032,69.63C58.724,69.63 57.661,68.972 57.661,68.162L57.661,66.694ZM78.637,30.369L85.588,30.369C89.678,30.369 93,38.508 93,48.531C93,58.555 89.678,66.694 85.588,66.694L78.637,66.694C81.853,64.225 84.182,57.023 84.182,48.531C84.182,40.041 81.853,32.838 78.637,30.369ZM44.823,30.381C48.031,32.86 50.352,40.054 50.352,48.531C50.352,57.01 48.03,64.203 44.823,66.682L22.702,59.738L11.573,59.738C9.049,59.738 7,54.717 7,48.531C7,42.347 9.049,37.325 11.573,37.325L22.702,37.325L44.823,30.381ZM11.347,41.036C12.355,42.742 13.008,45.465 13.008,48.531C13.008,51.598 12.355,54.321 11.347,56.027C10.614,54.321 10.139,51.598 10.139,48.531C10.139,45.465 10.614,42.741 11.347,41.036Z"
    ]
}
