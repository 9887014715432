import * as React from 'react';
import { ReactNode, MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCheckCircle, faExclamationCircle, faTimesCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

interface Props {
    automationId?: string;
    style?: 'info' | 'success' | 'warning' | 'danger';
    visible?: boolean;
    onClose?: (event: MouseEvent) => void;
    children?: ReactNode;
}

export const Alert = ({ automationId, style = 'info', visible = true, onClose, children }: Props) => {

    if (!visible)
        return null;

    const icon = style === 'info'
        ? (<div className="icon"><FontAwesomeIcon icon={faInfoCircle} /></div>)
        : style === 'success'
            ? (<div className="icon"><FontAwesomeIcon icon={faCheckCircle} /></div>)
            : style === 'warning'
                ? (<div className="icon"><FontAwesomeIcon icon={faExclamationCircle} /></div>)
                : style === 'danger'
                    ? (<div className="icon"><FontAwesomeIcon icon={faTimesCircle} /></div>)
                    : null;

    const dismiss = onClose
        ? (<button className="close" type="button" aria-label="Close" onClick={onClose}><FontAwesomeIcon icon={faTimes} size="xs" /></button>)
        : null;

    return (
        <div className={`alert alert-dismissible alert-${style}`} role="alert" data-automationid={automationId}>{icon}{children}{dismiss}</div>
    );
}
