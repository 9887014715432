import * as React from 'react';
import { Form, useFormApi } from 'informed';
import { useGetSettings, usePostDriversInstall, usePostSettingsRestore, usePostUpdateRestart } from '../../Api/DeviceLinkApi';
import { useEffect } from 'react';
import { Card, Select, Text, Checkbox, Button, useUniqueId, FormGroup } from '@vitalograph/web-ui';
import { faCog } from '@fortawesome/free-solid-svg-icons';

interface SettingsFormValues {
    serviceUrl: string;
    allowedOrigins: string;
    environment: string;
    logLevel: string;
    updateUrl: string;
    updatesEnabled: boolean;
}

const SettingsForm = () => {

    const [initGetSettings, , settings] = useGetSettings();
    const [initPostDriversInstall, ,] = usePostDriversInstall();
    const [initPostSettingsRestore, ,] = usePostSettingsRestore();
    const [initPostUpdateRestart, ,] = usePostUpdateRestart();
    const { setValues } = useFormApi<SettingsFormValues>();

    useEffect(() => {
        initGetSettings();
    }, []);

    useEffect(() => {
        setValues(settings);
    }, [settings]);

    const handleRestore = () => {
        initPostSettingsRestore();
    }

    const header = (
        <h4 className="m-0 text-primary">Settings</h4>
    );

    const footer = (
        <>
            <Button style="danger" onClick={handleRestore}>Restore</Button>
        </>
    );

    return (
        <Card icon={faCog} header={header} footer={footer}>
            <Text field="serviceUrl" label="Service URL" required={true} disabled={true} />
            <Text field="allowedOrigins" label="Allowed Origins" disabled={true} />
            <hr />
            <Select field="environment" label="Environment" options={["Development", "Staging", "Production"]} required={true} disabled={true} />
            <Select field="logLevel" label="Log Level" options={["Critical", "Error", "Warning", "Information", "Debug", "Trace"]} required={true} disabled={true} />
            <Text field="updateUrl" label="Update URL" required={true} disabled={true} />
            <Checkbox field="updatesEnabled" label="Updates Enabled" disabled={true} />
        </Card>
    );
}

export const Settings = () => (
    <Form>
        <SettingsForm />
    </Form>
);
