import * as React from 'react';
import { ReactNode } from 'react';
import { useAuth } from 'oidc-react';

type Role = 'device_manager' | 'device_authenticator';

interface AuthorizedProps {
    roles?: Role[];
    unauthenticated?: ReactNode;
    unauthorized?: ReactNode;
    children?: ReactNode;
}

const roleClaim = 'device_link_roles';

export const Authorized = ({ roles, unauthenticated, unauthorized, children }: AuthorizedProps) => {

    const { userData } = useAuth();

    if (!userData)
        return (<>{unauthenticated}</>);

    let userRoles = userData.profile[roleClaim] as Role[] ?? [];
    userRoles = Array.isArray(userRoles)
        ? userRoles
        : [userRoles];

    if (roles && (!userRoles.some(r => roles.includes(r))))
        return (<>{unauthorized}</>);

    return (<>{children}</>);
}
