import * as React from 'react';
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Card } from '@vitalograph/web-ui';

export const TroubleshooterCard = () => {

    const header = (
        <h4 className="m-0 text-primary">Troubleshooter</h4>
    );

    const footer = (
        <Link className="btn btn-primary" to="/troubleshoot">Open</Link>
    );

    return (
        <Card icon={faClipboardCheck} header={header} footer={footer}>
            <p>Check your system to ensure it meets the minimum requirements of Device Link and help diagnose any connection issues you may have with our servers.</p>
        </Card>
    );
}
