import * as React from "react";
import Moment from "moment";

export interface DatePickerProps {
    value: string;
    className: string;
    onChange: (value: string) => void;
    onBlur?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
}

export const DatePicker = ({ value, className, onChange, onBlur = null, placeholder = null, disabled = false, required = false }: DatePickerProps) => {
    return (
        <input
            type="date"
            max="9999-12-31"
            className={className}
            placeholder={placeholder}
            value={value ? Moment(value).format("YYYY-MM-DD") : ""}
            onChange={(e) => {
                onChange(e.target.value)
            }}
            onBlur={(e) => {
                if (onBlur) {
                    onBlur(Moment(e.target.value).format("YYYY-MM-DD"))
                }
            }}
            disabled={disabled}
            required={required}
        />
    );
}
