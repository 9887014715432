import * as React from 'react';
import { useState, useEffect } from 'react';
import { useODataQuery, ODataDirection } from './ODataQuery';
import { ODataFilterHeader } from './ODataTable';
import { ODataMatchFilterModal } from './ODataMatchFilterHeader';

export interface Option {
    label: any;
    value: any
}

interface ODataMatchSortHeaderProps {
    title: string;
    field: string;
    isCollectionFilter?: boolean;
    options: Option[];
    sortField?: string;
}

export const ODataMatchSortHeader = ({ title, field, isCollectionFilter = false, options, sortField = null }: ODataMatchSortHeaderProps) => {

    const [display, setDisplay] = useState(false);
    const { filters, ordering, applyOrdering } = useODataQuery();
    const [ordered, setOrdered] = useState<ODataDirection>();
    const [filtered, setFiltered] = useState(false);
    sortField = sortField ?? field;

    useEffect(() => {

        const filtered = filters.some(f => f.field === field && f.condition === 'eq');
        setFiltered(filtered);
    }, [filters]);

    useEffect(() => {

        const ordered = ordering.find(o => o.field === sortField);
        setOrdered(ordered
            ? ordered.condition
            : null);
    }, [ordering]);

    const handleSort = () => {
        let direction: ODataDirection = 'asc';

        if (ordered === 'asc')
            direction = 'desc';

        applyOrdering([{ field: sortField, condition: direction }]);
    }

    const handleFilter = () => {
        setDisplay(true);
    }

    const modal = display
        ? (
            <ODataMatchFilterModal title={title} field={field} isCollectionFilter={isCollectionFilter} options={options} onClose={() => setDisplay(false)} />
        )
        : null;

    return (
        <>
            <ODataFilterHeader filtered={filtered} sorted={ordered} isCollectionFilter={isCollectionFilter} onFilter={handleFilter} onSort={handleSort}>{title}</ODataFilterHeader>
            {modal}
        </>
    );
}
