import * as React from 'react';
import ReactSelect from 'react-select';
import { useField } from 'informed';
import { FormGroup } from './FormGroup';
import { useUniqueId } from '../Hooks/UniqueId';
import { Validator, noValidation } from '../Validators/Validator';
import { validateMany } from '../Validators/Many';
import { validateRequired } from '../Validators/Required';
import strings from '../strings.json';

interface Option {
    label: string;
    value: string;
}

interface SelectProps {
    field: string;
    automationId?: string;
    options: Array<string | Option>;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    loading?: boolean;
    multiple?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    onValidate?: Validator;
    requiredMessage?: string;
    loadingMessage?: string;
    onBlur?: (value: any) => void;
}

export const Select = ({ field, automationId, label = '', placeholder = strings.select, options, disabled = false, loading = false, multiple = false, autoFocus = false, required = false, onValidate = noValidation, requiredMessage = strings.required, loadingMessage = strings.loading, onBlur = () => { } }: SelectProps) => {

    const handleValidation = validateMany([
        validateRequired(required, requiredMessage),
        onValidate,
    ]);

    const uniqueId = useUniqueId();
    const { ref, render, fieldState, fieldApi } = useField<any>({ field: field, validate: handleValidation });
    const { value, error } = fieldState;
    const { setValue, setTouched } = fieldApi;

    const cleanOptions = options.map(option => typeof option === 'string' || !option
            ? { label: option, value: option }
            : option);

    const cleanValues = Array.isArray(value)
        ? cleanOptions.filter(option => value.includes(option.value))
        : cleanOptions.find(option => option.value === value);

    const handleBlur = (e: React.FocusEvent<any>) => {
        setTouched(true);
        onBlur(e.target.value);
    }

    const handleChange = (selected: any) => {

        if (selected == null)
            setValue(null);
        else if (Array.isArray(selected))
            setValue(selected.map(select => select.value));
        else
            setValue(selected.value);
    }

    const errorMessage = error
        ? (<div style={{ display: 'block' }} className="invalid-feedback">{error}</div>)
        : null;

    return render(
        <FormGroup label={label} htmlFor={uniqueId}>
            <ReactSelect ref={ref} id={uniqueId} value={cleanValues} options={cleanOptions} isMulti={multiple} autoFocus={autoFocus} isDisabled={disabled} isLoading={loading} placeholder={placeholder} loadingMessage={() => loadingMessage} onChange={handleChange} onBlur={handleBlur} data-automationid={automationId} />
            {errorMessage}
        </FormGroup>
    );
}
