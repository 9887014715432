import * as React from 'react';
import { useEffect } from 'react';
import { useGetLogs } from '../../Api/DeviceLinkApi';
import { LogCard } from './LogCard';

interface LogsProps {
    refreshInterval?: number;
}

export const Logs = ({ refreshInterval = 2000 }: LogsProps) => {

    const [initLogs, , logs] = useGetLogs();

    const refreshLogs = () => {

        initLogs();
        setTimeout(refreshLogs, refreshInterval);
    }

    useEffect(refreshLogs, []);

    const list = logs.map(log => <LogCard key={log.id} {...log} />)

    return (
        <>
            {list}
        </>
    );
}
