import { Validator } from './Validator';
import strings from '../strings.json';

export const validateRequired = (required: boolean, message: string = strings.required): Validator => {

    return (fieldValues) => {

        if (!required)
            return;

        const values = Array.isArray(fieldValues)
            ? fieldValues
            : [fieldValues];

        if (values.length === 0)
            return message;

        for (const value of values)
            if (value == null || value === '')
                return message;
    }
}
