import * as React from 'react';
import { TroubleshootStepProps, TroubleshootStep } from './Troubleshoot';
import { Button } from '@vitalograph/web-ui';

export const InstallationStep = ({ visible, onChangeStep }: TroubleshootStepProps) => {
    if (!visible)
        return null;

    const handleBackClick = () => onChangeStep(TroubleshootStep.UserDetails);
    const handleNextClick = () => onChangeStep(TroubleshootStep.SystemDetails);

    return (
        <>
            <div className="card-body">
                <p className="font-weight-bold">We were unable to detect Device Link on your system.</p>
                <p>Please download and install Device Link before continuing, this shall allow information to be collected about your system.</p>
                <p><a className="btn btn-primary" href="https://devicelink.vitalograph-clinical.info/setup.exe">Device Link Installer</a></p>
                <p>Please leave this screen open while installing Device Link and follow the on-screen instructions.</p>
                <p>Once the software has been installed please continue.</p>
            </div>
            <div className="card-footer text-right">
                <Button onClick={handleBackClick}>Back</Button>
                &nbsp;&nbsp;&nbsp;
                <Button style="success" onClick={handleNextClick}>Next</Button>
            </div>
        </>
    );
}
