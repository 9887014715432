import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const mapParams = (location: any) => {
    const search = new URLSearchParams(location.search);
    let params = {} as any;

    search.forEach((value, key) => {
        params[key] = value;
    });

    return params;
}

export const useUrlParams = () => {

    const location = useLocation();
    const [params, setParams] = useState(() => mapParams(location));

    useEffect(() => setParams(mapParams(location)), [location]);

    return params;
}
