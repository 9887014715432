import * as React from 'react';
import { useQuery, Query, Direction } from '../Query';
import { Form, useFormApi } from 'informed';
import { FilterHeader } from '../Table';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Select } from '../Select';

interface MatchFilterHeaderProps {
    title: string;
    field: string;
}

export const MatchFilterHeader = ({ title, field }: MatchFilterHeaderProps) => {

    const [displayFilter, setDisplayFilter] = React.useState(false);
    const { provider, filters, filter, orderBy, orderDirection, order, changePage } = useQuery();

    const ordered = orderBy === field
        ? orderDirection
        : 'unordered';

    const filtered = filters.some(filter => filter.member === field);
    const currentFilter = filters.find(filter => filter.member === field && filter.condition === 'equal');
    const initialValues = currentFilter
        ? currentFilter.values
        : [];

    const handleSort = () => {
        let direction: Direction = 'ascending';

        if (orderDirection == 'ascending')
            direction = 'descending';

        order(field, direction);
    }

    const handleFilter = () => {
        setDisplayFilter(true);
    }

    const handleApply = (values: string[]) => {
        const newFilters = filters.filter(filter => filter.member !== field || filter.condition !== 'equal');

        if (values.length > 0)
            newFilters.push({ member: field, condition: 'equal', values: values });

        changePage(1);
        filter(newFilters);
        setDisplayFilter(false);
    }

    const modal = displayFilter
        ? (
            <Query provider={provider} fields={[field]} distinct={true} initialOrderBy={field} pageSize={0}>
                <Form>
                    <MatchFilterModal title={title} field={field} initialValues={initialValues} onApply={handleApply} onClose={() => setDisplayFilter(false)} />
                </Form>
            </Query>
        )
        : null;

    return (
        <>
            <FilterHeader key={`MatchFilter_${title}`} sorted={ordered} onSort={handleSort} filtered={filtered} onFilter={handleFilter}>{title}</FilterHeader>
            {modal}
        </>
    );
}

export interface MatchFilterForm {
    match: string[];
}

interface MatchFilterModalProps {
    title: string;
    field: string;
    initialValues: string[];
    onApply: (values: string[]) => void;
    onClose: () => void;
}

export const MatchFilterModal = ({ title, field, initialValues, onApply, onClose }: MatchFilterModalProps) => {

    const { data } = useQuery();
    const { setValue, getState } = useFormApi<MatchFilterForm>();
    const options = data
        .filter(option => option[field] && option[field] !== '')
        .map(option => typeof (option[field]) !== 'string'
            ? option[field].toString()
            : option[field] as string
        );

    React.useEffect(() => setValue('match', initialValues), [data]);

    const handleApply = () => {
        var state = getState();

        if (state.invalid)
            return;

        const values = state.values.match;

        onApply(values);
    }

    const handleClear = () => {
        setValue("match", []);
        
        handleApply();
    }

    const header = (
        <>
            <h3 className="text-primary">{title} Filter</h3>
            <button type="button" className="close" onClick={onClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </>
    );

    const footer = (
        <>
            <Button automationId="apply" onClick={handleApply}>Apply</Button>
            <Button automationId="clear" style="danger" onClick={handleClear}>Clear</Button>
        </>
    );

    return (
        <Modal header={header} footer={footer} size="md">
            <Select automationId="match" field="match" label="Match" multiple={true} options={options} autoFocus={true} />
        </Modal>
    );
};
