import * as React from 'react';
import { faCircleNotch, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, faStatStripXpress2, faVitaloJak1, faVitaloJak2, faDiary, faPneumotrac, Modal, Loading, Success, Error, Query } from '@vitalograph/web-ui';
import { DeviceStatus } from './DeviceStatus';
import { Authorized } from '../../Ui';
import { postDeviceCommandNoResponse } from '../../Api/DeviceLinkApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useDeviceLinkDashboard } from '../../Hooks/DeviceLinkDashboard';
import { useAuth } from 'oidc-react';
import { getClients, putClient } from '../../Api/AccountApi';

interface DeviceCardProps {
    id: string;
    type: string;
    name: string;
    vendor: string;
    connection: string;
    hardware: string;
    firmware: string;
    serial: string;
    status: string;
}

interface FooterProps {
    id: string;
    serial: string;
    type: string
}

const waitIcon = (
    <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
);

const VitaloJakFooter = ({ id }: FooterProps) => {

    const [busy, setBusy] = useState(false);

    const handleReset = async () => {
        setBusy(true);
        await postDeviceCommandNoResponse(id, 'reset');
        setBusy(false);
    }

    return (
        <>
            {busy ? waitIcon : null}
            <Authorized roles={['device_manager']}>
                <div className="btn-group ml-2">
                    <Button style="danger" disabled={busy} onClick={handleReset}>Reset</Button>
                </div>
            </Authorized>
        </>
    );
}

const DiaryFooter = ({ id, serial, type }: FooterProps) => {

    const { about } = useDeviceLinkDashboard();
    const auth = useAuth();
    const [busy, setBusy] = useState(false);
    const [authStatus, setAuthStatus] = useState<'unknown' | 'authed' | 'error'>('unknown');
    const [accuracyCheckStatus, setAccuracyCheckStatus] = useState<'unknown' | 'launched' | 'error'>('unknown');

    const handleRestart = async () => {
        setBusy(true);
        await postDeviceCommandNoResponse(id, 'restart');
        setBusy(false);
    }

    const handleReset = async () => {
        setBusy(true);
        await postDeviceCommandNoResponse(id, 'reset');
        setBusy(false);
    }

    const handleAuthorize = async () => {

        setBusy(true);

        try {
            const token = auth.userData?.access_token ?? '';
            const clientsResponse = await getClients(about.oAuthAddress, token, {
                distinct: false,
                count: 0,
                data: [],
                filters: [{
                    member: 'serial',
                    condition: 'equal',
                    values: [serial],
                }],
            });

            if (clientsResponse.count > 1) {
                throw 'Too many clients match the device serial!';
            }
            else if (clientsResponse.data.length === 1) {
                await postDeviceCommandNoResponse(id, 'setauthenticationkey', clientsResponse.data[0].label);
                setAuthStatus('authed');
            }
            else {
                const clientResponse = await putClient(about.oAuthAddress, token, {
                    "name": `${type}_${serial}`,
                    "grants": [
                        "client_credentials"
                    ],
                    "scopes": [
                        "device"
                    ],
                    "requireClientSecret": false,
                    "allowOfflineAccess": true,
                    "serial": serial
                });

                await postDeviceCommandNoResponse(id, 'setauthenticationkey', clientResponse.label);
                setAuthStatus('authed');
            }
        }
        catch (e) {
            setAuthStatus('error');
            throw e;
        }
        finally {
            setBusy(false);
        }
    }

    const handleStartAccuracyCheck = async () => {
        setBusy(true);
        try {
            await postDeviceCommandNoResponse(id, 'launchAccuracyRoutine');
            setAccuracyCheckStatus('launched');
        } catch (e) {
            setAccuracyCheckStatus('error');
            throw e;
        } finally {
            setBusy(false);
        }
    }

    const authModal = authStatus !== 'unknown'
        ? (
            <Modal header={<h4 className="text-primary">Authorization</h4>} footer={<Button onClick={() => setAuthStatus('unknown')}>Close</Button>}>
                {authStatus === 'authed' ? <Success message="Device Authorization Success" /> : null}
                {authStatus === 'error' ? <Error message="Device Authorization Failed" /> : null}
            </Modal>
        )
        : null;

    const accuracyCheckModal = accuracyCheckStatus !== 'unknown'
        ? (
            <Modal header={<h4 className="text-primary">Accuracy Check</h4>} footer={<Button onClick={() => setAccuracyCheckStatus('unknown')}>Close</Button>}>
                {accuracyCheckStatus === 'launched' ? <Success message="Disconnect the USB cable and follow the instructions on the device to perform an accuracy check" /> : null}
                {accuracyCheckStatus === 'error' ? <Error message="Device Accuracy Check failed to launch" /> : null}
            </Modal>
        )
        : null;

    return (
        <>
            {authModal}
            {accuracyCheckModal}
            {busy ? waitIcon : null}
            <div className="btn-group ml-2">
                <Button style="primary" disabled={busy} onClick={handleStartAccuracyCheck}>Accuracy Check</Button>
            </div>
            <Authorized roles={['device_manager']}>
                <div className="btn-group ml-2">
                    <Button disabled={busy} onClick={handleRestart}>Restart</Button>
                </div>
                <div className="btn-group ml-2">
                    <Button style="danger" disabled={busy} onClick={handleReset}>Reset</Button>
                </div>
            </Authorized>
            <Authorized roles={['device_authenticator']}>
                <div className="btn-group ml-2">
                    <Button style="warning" disabled={busy} onClick={handleAuthorize}>Authorize</Button>
                </div>
            </Authorized>
        </>
    );
}

export const DeviceCard = ({ id, type, name, vendor, connection, hardware, firmware, serial, status }: DeviceCardProps) => {

    const header = (
        <h4 className="m-0 text-primary">{name}</h4>
    );

    let footer;
    switch (type) {
        case 'DT_VITALOJAK':
            footer = <VitaloJakFooter id={id} serial={serial} type={type} />
            break;
        case 'DT_P230_EDIARY':
            footer = <DiaryFooter id={id} serial={serial} type={type} />
            break;
        default:
            footer = <></>;
    }

    let icon;
    switch (type) {
        case 'DT_VITALOJAK_I':
            icon = faVitaloJak1;
            break;
        case 'DT_VITALOJAK':
            icon = faVitaloJak2;
            break;
        case 'DT_STATSTRIP_XPRESS_2':
            icon = faStatStripXpress2;
            break;
        case 'DT_P230_EDIARY':
            icon = faDiary;
            break;
        case 'DT_MODEL_6800':
            icon = faPneumotrac;
            break;
        default:
            icon = faQuestionCircle;
    }

    return (
        <Card icon={icon} header={header} footer={footer}>
            <table className="table table-borderless table-sm">
                <tbody>
                    <tr>
                        <th>Vendor</th>
                        <td>{vendor}</td>
                    </tr>
                    <tr>
                        <th>Connection</th>
                        <td>{connection}</td>
                    </tr>
                    <tr>
                        <th>Hardware</th>
                        <td>{hardware}</td>
                    </tr>
                    <tr>
                        <th>Firmware</th>
                        <td>{firmware}</td>
                    </tr>
                    <tr>
                        <th>Serial</th>
                        <td>{serial}</td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td><DeviceStatus status={status} /></td>
                    </tr>
                </tbody>
            </table>
        </Card>
    );
}
