import * as React from 'react';
import { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import { About, useGetAbout } from '../Api/DashboardApi';

interface DeviceLinkDashboardState {
    online: boolean;
    about: About;
}

interface DeviceLinkDashboardProps {
    refreshInterval?: number;
    children?: ReactNode;
}

const defaultRefreshInterval = 60000;
const defaultDeviceLinkDashboardState = {
    online: false,
    about: {
        service: '',
        description: '',
        version: '',
        oAuthAddress: '',
        oAuthClientId: '',
        oAuthScopes: '',
        oAuthRedirect: '',
        deviceLinkAddress: '',
        installerWin64Address: '',
    },
}

const deviceLinkDashboardContext = createContext<DeviceLinkDashboardState>(defaultDeviceLinkDashboardState);
const DeviceLinkDashboardProvider = deviceLinkDashboardContext.Provider;

export const useDeviceLinkDashboard = () => useContext(deviceLinkDashboardContext);

export const DeviceLinkDashboard = ({ refreshInterval = defaultRefreshInterval, children }: DeviceLinkDashboardProps) => {

    const [online, setOnline] = useState(false);
    const [initAbout, aboutStatus, about] = useGetAbout();

    const refreshAbout = () => {

        initAbout();
        setTimeout(refreshAbout, refreshInterval);
    }

    useEffect(refreshAbout, [refreshInterval]);

    useEffect(() => {

        if (aboutStatus === 'success')
            setOnline(true);
        else if (aboutStatus === 'error')
            setOnline(false);
    }, [aboutStatus]);

    return (
        <DeviceLinkDashboardProvider value={{ online: online, about: about }}>
            {children}
        </DeviceLinkDashboardProvider>
    );
}
