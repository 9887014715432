import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faDiary: IconDefinition = {
    prefix: "fab",
    iconName: "edit",
    icon: [
        24,
        32,
        [],
        "0060",
        "M12,26.022007C12.828003,26.022007 13.5,26.694004 13.5,27.522007 13.5,28.35001 12.828003,29.022007 12,29.022007 11.171997,29.022007 10.5,28.35001 10.5,27.522007 10.5,26.694004 11.171997,26.022007 12,26.022007z M6,7L6,22.999999 18,22.999999 18,7z M4,5L20,5 20,24.999999 4,24.999999z M2,2L2,29.999999 22,29.999999 22,2z M0,0L24,0 24,31.999999 0,31.999999z"
    ]
}
