import { Validator } from './Validator';

export const validateMany = (validators: Validator[]): Validator => {

    return (fieldValues, formValues) => {

        for (const validator of validators) {

            const result = validator(fieldValues, formValues);

            if (result)
                return result;
        }
    }
}
