import * as React from 'react';
import { ReactNode } from 'react';

interface FormGroupProps {
    label?: string;
    htmlFor?: string;
    children?: ReactNode;
}

export const FormGroup = ({ label, htmlFor, children }: FormGroupProps) => {

    const labelWrapped = htmlFor
        ? (<label className="col-form-label col-xl-4 text-xl-right" htmlFor={htmlFor}>{label}</label>)
        : (<div className="col-xl-4 text-xl-right font-weight-bold">{label}</div>);

    return (
        <div className="row form-group">
            {labelWrapped}
            <div className="col-xl-8">
                {children}
            </div>
        </div>
    );
}
