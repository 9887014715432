import * as React from 'react';
import { useState } from 'react';
import { TroubleshootStepProps, TroubleshootStep } from './Troubleshoot';
import { Button } from '@vitalograph/web-ui';
import { Link } from 'react-router-dom';

export const TermsAndConditionsStep = ({ visible, onChangeStep }: TroubleshootStepProps) => {
    if (!visible)
        return null;

    const [agreed, setAgreed] = useState<boolean>(false);
    const handleNext = () => onChangeStep(TroubleshootStep.UserDetails);

    return (
        <>
            <div className="card-body">
                <p>This process shall allow us to assess your system; ensuring it meets the minimum requirements to run the software and help diagnose any connection issues you may have with our servers.</p>
                <p>During this process the following information shall be collected:</p>
                <ul>
                    <li>Site name, address, country, time zone, and primary contact name</li>
                    <li>Operating system and version number</li>
                    <li>Dot Net framework and version number</li>
                    <li>Browser and version number</li>
                    <li>Upload and download speeds</li>
                    <li>IP address</li>
                </ul>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-sm-6 pt-2">
                        <div className="custom-control custom-checkbox">
                            <input id="form-control-0" className="custom-control-input" type="checkbox" autoFocus={true} onClick={() => setAgreed(!agreed)} />
                            <label className="custom-control-label" htmlFor="form-control-0">I consent to Vitalograph collecting this information</label>
                        </div>
                    </div>
                    <div className="col-sm-6 text-right">
                        <Link className="btn btn-primary" to="/">Cancel</Link>
                        &nbsp;&nbsp;&nbsp;
                        <Button style="success" onClick={handleNext} disabled={!agreed}>Start</Button>
                    </div>
                </div>
            </div>
        </>
    );
}
