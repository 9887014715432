import * as React from 'react';
import { useState, useEffect } from 'react';
import { TroubleshootStepProps, TroubleshootStep } from './Troubleshoot';
import { useGetAbout } from '../../Api/DeviceLinkApi';
import { usePutSurvey, useUploadTest, usePostSurveyResults } from '../../Api/DashboardApi';
import { UAParser } from 'ua-parser-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export const SystemDetailsStep = ({ visible, details, onChangeStep, onDetailsChange }: TroubleshootStepProps) => {
    if (!visible)
        return null;

    const [message, setMessage] = useState<string>();
    const [created, setCreated] = useState<boolean>(false);
    const [initSurvey, surveyStatus, survey] = usePutSurvey();
    const [initAbout, aboutStatus, about] = useGetAbout();
    const [initUploadTest, uploadStatus, upload] = useUploadTest();
    const [initDownloadTest, downloadStatus, download] = useUploadTest();
    const [initResults, resultsStatus, results] = usePostSurveyResults();

    useEffect(() => {
        if (details.id)
            return setCreated(true);

        setMessage('Saving user information');
        initSurvey(details);
    }, []);

    useEffect(() => {
        if (surveyStatus !== 'success')
            return;

        setCreated(true);
    }, [surveyStatus]);

    useEffect(() => {
        if (!created)
            return;

        if (survey)
            onDetailsChange(survey);

        setMessage('Gathering system information');
        initAbout();
    }, [created]);

    useEffect(() => {
        if (aboutStatus !== 'error')
            return;

        onChangeStep(TroubleshootStep.Installation);
    }, [aboutStatus]);

    useEffect(() => {
        if (aboutStatus !== 'success')
            return;

        setMessage('Checking upload speed');
        initUploadTest();
    }, [aboutStatus]);

    useEffect(() => {
        if (uploadStatus !== 'success')
            return;

        setMessage('Checking download speed');
        initDownloadTest();
    }, [uploadStatus]);

    useEffect(() => {
        if (downloadStatus !== 'success')
            return;

        if (!about)
            throw 'About should not be null';

        setMessage('Saving results');

        const browser = (new UAParser()).getBrowser();

        initResults({
            ...details,
            timeZoneId: about.timeZone,
            operatingSystem: about.operatingSystem,
            architecture: about.architecture,
            framework: about.framework,
            browser: `${browser.name} ${browser.version}`,
            uploadSpeed: upload,
            downloadSpeed: download,
        });
    }, [downloadStatus]);

    useEffect(() => {
        if (resultsStatus !== 'success')
            return;

        if (!results)
            throw 'Results should not be null';

        onDetailsChange(results);
        onChangeStep(TroubleshootStep.Results);
    }, [resultsStatus]);

    return (
        <>
            <div className="card-body">
                <div className="pt-3 text-center">
                    <FontAwesomeIcon icon={faCircleNotch} spin={true} size="5x" color="#00aeef" />
                    <p className="pt-3 font-weight-bold">Please Wait</p>
                    <p>{message}</p>
                </div>
            </div>
        </>
    );
}
