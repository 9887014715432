import { Validator } from './Validator';
import { validatePattern } from './Pattern';
import strings from '../strings.json';

export const validateUrl = (message: string = strings.invalidUrl): Validator => {

    const url = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

    return validatePattern(url, message);
}
