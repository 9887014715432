import * as React from 'react';
import { Link } from 'react-router-dom';
import { faVitalograph } from '@vitalograph/web-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from 'oidc-react';
import { useDeviceLink } from '../Hooks/DeviceLink';
import { DropDown } from './Dropdown';

export const Navbar = () => {

    const deviceLink = useDeviceLink();
    const { signIn, signOut, userData } = useAuth();

    const apiLink = deviceLink.about.environment === 'Development'
        ? (
            <li className="nav-item">
                <a className="nav-link" target='_blank' href={`${deviceLink.about.connection}swagger`}>API</a>
            </li>
        )
        : null;

    const menu = deviceLink.online
        ? (
            <>
                <li className="nav-item">
                    <Link className="nav-link" to="/logs">Logs</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to="/settings">Settings</Link>
                </li>
                {apiLink}
            </>
        )
        : null;

    const profile = userData
        ? (
            <DropDown label={<><img className="profile-picture mr-1" src={userData.profile.picture} alt="" /> <span className="navbar-text">{userData.profile.name}</span></>}>
                <a className="dropdown-item" onClick={signOut}>Sign Out</a>
            </DropDown>
        )
        : (
            <a className="nav-link" onClick={signIn}>Sign In</a>
        );

    return (
        <nav className="navbar navbar-expand-md navbar-dark bg-primary no-select">
            <Link className="navbar-brand" to="/">
                <FontAwesomeIcon className="mr-3" icon={faVitalograph} size="lg" />
                <span>Device Link</span>
            </Link>
            <ul className="navbar-nav mr-auto">
                {menu}
            </ul>
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    {profile}
                </li>
            </ul>
        </nav>
    );
}
