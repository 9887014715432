import { Validator } from './Validator';
import { validatePattern } from './Pattern';
import strings from '../strings.json';

export const validateEmail = (message: string = strings.invalidEmail): Validator => {

    const email = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    return validatePattern(email, message);
}
