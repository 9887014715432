import { Validator } from './Validator';
import strings from '../strings.json';

export const validatePattern = (regex?: RegExp, message: string = strings.invalidFormat): Validator => {

    return (fieldValues) => {

        const values = Array.isArray(fieldValues)
            ? fieldValues
            : [fieldValues];

        for (const value of values)
            if (regex && !regex.test(`${value}`))
                return message;
    }
}
