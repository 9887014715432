import * as React from 'react';
import { faCircleNotch, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Bytes, DateTime, Card, Button } from '@vitalograph/web-ui';
import { useGetLogsClear } from '../../Api/DeviceLinkApi';
import { useDeviceLink } from '../../Hooks/DeviceLink';

interface LogCardProps {
    id: string;
    size: number;
    isCurrent: boolean;
    created: string;
    modified: string;
}

const waitIcon = (
    <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
);

export const LogCard = ({ id, size, created, modified, isCurrent }: LogCardProps) => {

    const { about } = useDeviceLink();
    const [initClear, clearStatus] = useGetLogsClear();

    const handleOpen = () => {
        window.open(`${about.connection}api/v1/logs/${id}/open`, "_blank");
    }

    const busy = [clearStatus].some(s => s === 'fetching');
    const busyIcon = busy
        ? waitIcon
        : null;
    const clearButton = isCurrent
        ? (
            <>
                {busyIcon}
                <div className="btn-group ml-2">
                    <Button style="danger" disabled={busy} onClick={initClear}>Clear</Button>
                </div>
            </>
        )
        : null;

    const header = (
        <h4 className="m-0 text-primary">Log {id}</h4>
    );

    const footer = (
        <>
            {clearButton}
            <div className="btn-group ml-2">
                <Button disabled={busy} onClick={handleOpen}>Open</Button>
            </div>
        </>
    );

    return (
        <Card icon={faClipboard} header={header} footer={footer}>
            <table className="table table-borderless table-sm">
                <tbody>
                    <tr>
                        <th>Size</th>
                        <td><Bytes value={size} /></td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td><DateTime value={created} accuracy="minute" displayOffset={false} /></td>
                    </tr>
                    <tr>
                        <th>Modified</th>
                        <td><DateTime value={modified} accuracy="minute" displayOffset={false} /></td>
                    </tr>
                </tbody>
            </table>
        </Card>
    );
}
