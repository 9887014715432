import * as React from 'react';
import { MatchFilterForm } from './MatchFilterHeader';
import { useQuery, Direction, Query } from '../Query';
import { useFormApi, Form } from 'informed';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { Select } from '../Select';
import { FilterHeader } from '../Table';

interface BooleanFilterModalProps {
    title: string;
    initialValues: string[];
    customLabels: string[];
    onApply: (values: string[]) => void;
    onClose: () => void;
    automationId?: string;
}

interface BooleanFilterHeaderProps {
    title: string;
    field: string;
    customLabels: string[]
}

export const BooleanFilterHeader = ({ title, field, customLabels }: BooleanFilterHeaderProps) => {

    const [displayFilter, setDisplayFilter] = React.useState(false);
    const { provider, filters, filter, orderBy, orderDirection, order, changePage } = useQuery();

    const ordered = orderBy === field
        ? orderDirection
        : 'unordered';

    const filtered = filters.some(filter => filter.member === field);
    const currentFilter = filters.find(filter => filter.member === field && filter.condition === 'equal');
    const initialValues = currentFilter
        ? currentFilter.values
        : [];

    const handleSort = () => {
        let direction: Direction = 'ascending';

        if (orderDirection == 'ascending')
            direction = 'descending';

        order(field, direction);
    }

    const handleFilter = () => {
        setDisplayFilter(true);
    }

    const handleApply = (values: string[]) => {
        const newFilters = filters.filter(filter => filter.member !== field || filter.condition !== 'equal');

        if (values.length > 0)
            newFilters.push({ member: field, condition: 'equal', values: values });

        changePage(1);
        filter(newFilters);
        setDisplayFilter(false);
    }

    const modal = displayFilter
        ? (
            <Query provider={provider} fields={[field]} distinct={true} initialOrderBy={field} pageSize={0}>
                <Form>
                    <BooleanFilterModal title={title} initialValues={initialValues} customLabels={customLabels} onApply={handleApply} onClose={() => setDisplayFilter(false)} />
                </Form>
            </Query>
        )
        : null;

    return (
        <>
            <FilterHeader key={`BooleanFilter_${title}`} sorted={ordered} onSort={handleSort} filtered={filtered} onFilter={handleFilter}>{title}</FilterHeader>
            {modal}
        </>
    );
}

export const BooleanFilterModal = ({ automationId, title, initialValues, customLabels, onApply, onClose }: BooleanFilterModalProps) => {

    const { data } = useQuery();
    const { setValue, getState } = useFormApi<MatchFilterForm>();
    const options = [{ label: customLabels[0], value: "true" }, { label: customLabels[1], value: "false" }];

    React.useEffect(() => setValue('match', initialValues), [data]);

    const handleApply = () => {
        var state = getState();

        if (state.invalid)
            return;

        const values = state.values.match;

        onApply(values);
    }

    const handleClear = () => {
        setValue('match', []);
        handleApply();
    }

    const header = (
        <>
            <h3 className="text-primary">{title} Filter</h3>
            <button type="button" className="close" onClick={onClose} aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </>
    );

    const footer = (
        <>
            <Button automationId="apply" onClick={handleApply}>Apply</Button>
            <Button automationId="clear" style="danger" onClick={handleClear}>Clear</Button>
        </>
    );

    return (
        <Modal header={header} footer={footer} size="md" automationId={automationId}>
            <Select automationId="match" field="match" label="Match" multiple={true} options={options} autoFocus={true} />
        </Modal>
    );
};
