import * as React from 'react';
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DeviceStatusProps {
    status: string
}

export const DeviceStatus = ({ status }: DeviceStatusProps) => {

    if (status === 'DS_READY')
        return <span className="formatter formatter-device-status">Ready <FontAwesomeIcon className="text-success" icon={faCheckCircle} /></span>;

    if (status === 'DS_BUSY')
        return <span className="formatter formatter-device-status">Busy <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} /></span>;

    if (status === 'DS_ERROR')
        return <span className="formatter formatter-device-status">Error <FontAwesomeIcon className="text-danger" icon={faTimesCircle} /></span>;

    if (status === 'DS_UPDATING')
        return <span className="formatter formatter-device-status">Updating <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} /></span>;

    if (status === 'DS_OFFLINE')
        return <span className="formatter formatter-device-status">Offline <FontAwesomeIcon className="text-danger" icon={faTimesCircle} /></span>;

    if (status === 'DS_INITIALIZING')
        return <span className="formatter formatter-device-status">Initializing <FontAwesomeIcon className="text-danger" icon={faExclamationCircle} /></span>;

    throw `Unrecognized device status '${status}' cannot be formatted.`;
}
