import * as React from 'react';
import classNames from 'classnames';
import { ODataDirection, useODataQuery } from './ODataQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faFilter, faInfoCircle, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../Button';

export interface ODataColumnProps<T> {
    field?: string;
    header?: React.ReactNode;
    formatter?: (value: string | number | string[] | number[] | T) => React.ReactNode;
}

interface ODataTableProps<T> {
    rowKeyField: keyof(T);
    data: T[];
    automationId?: string;
    children?: React.ReactElement<ODataColumnProps<T>>[];
}

const ODataRowContext = React.createContext<any>({});

export const useODataRow = () => React.useContext(ODataRowContext);

export const ODataTable = <T extends any> ({ rowKeyField, data, automationId, children }: ODataTableProps<T>) => {
    const { refreshing, filters, applyFilters, count } = useODataQuery();

    const headers = children.map((child, index) => (
        <th key={`header${index}`} className="text-nowrap" data-automationid={`header${index}`}>{child.props.header}</th>
    ));

    const rows = data.map((value, index) => (
        <ODataRowContext.Provider key={`row${value[rowKeyField]}`} value={value}>
            <tr data-automationid={`row${index}`}>{children.map((child, index) => <td key={`column${child.props.field}`} className="text-nowrap" data-automationid={`column${index}`}>{child}</td>)}</tr>
        </ODataRowContext.Provider>
    ));

    const refreshBody = refreshing && count < 1
        ? (
            <tbody>
                <tr>
                    <td colSpan={children.length}>
                        <p className="text-center">Querying for data</p>
                    </td>
                </tr>
            </tbody>
        )
        : null;

    const emptyBody = !refreshing && count < 1
        ? (
            <tbody>
                <tr>
                    <td colSpan={children.length}>
                        <p className="text-center">No data available</p>
                    </td>
                </tr>
            </tbody>
        )
        : null;

    const body = count > 0
        ? (
            <tbody className={`${refreshing ? 'text-muted' : ''}`}>{rows}</tbody>
        )
        : null;

    const alert = filters.length > 0
        ? (
            <tr>
                <td className="alert alert-info" colSpan={children.length}>
                    <div className="icon"><FontAwesomeIcon icon={faInfoCircle} /></div>One or more filters are applied to the results. <Button style="link" onClick={() => applyFilters([])}>Clear filters</Button>
                </td>
            </tr>
        )
        : null;

    return (
        <div className="table-responsive rounded-top">
            <table className="table table-striped table-hover" data-automationid={automationId}>
                <thead className="thead-dark">
                    <tr>{headers}</tr>
                    {alert}
                </thead>
                {refreshBody}
                {emptyBody}
                {body}
            </table>
            <TablePagination />
        </div>
    );
}

export const ODataColumn = <T extends any> ({ field, formatter = (value) => value }: ODataColumnProps<T>) => {

    const row = useODataRow();
    const value = field
        ? row[field]
        : null;
    const formatted = field ? formatter(value) : formatter(row);

    return (
        <>{formatted}</>
    );
}

interface ODataFilterHeaderProps {
    sorted?: ODataDirection;
    filtered?: boolean;
    isCollectionFilter?: boolean;
    onSort?: () => void;
    onFilter?: () => void;
    children?: React.ReactNode;
}

export const ODataFilterHeader = ({ sorted = null, filtered = false, isCollectionFilter = false, onSort, onFilter, children }: ODataFilterHeaderProps) => {

    const sortIcon = sorted === null
        ? faSort
        : sorted === 'desc'
            ? faSortDown
            : faSortUp;

    const sortedClass = sorted !== null
        ? 'action active'
        : 'action';

    const filteredClass = filtered
        ? 'action active'
        : 'action';

    const sort = !isCollectionFilter && (sorted || onSort)
        ? <button className={sortedClass} style={{ display: "inline-block", paddingLeft: "8px" }} onClick={onSort}><FontAwesomeIcon icon={sortIcon} /></button>
        : null;

    const filter = filtered || onFilter
        ? <button className={filteredClass} style={{ display: "inline-block", paddingLeft: "8px" }} onClick={onFilter}><FontAwesomeIcon icon={faFilter} /></button>
        : null;

    return (
        <div className="query-header">
            {children}
            {sort}
            {filter}
        </div>
    );
}

const TablePagination = () => {
    const { count, paging, applyPaging } = useODataQuery();
    const { page, size } = paging;
    const pageTotal = Math.ceil(count / size);
    const firstPage = 1;
    const lastPage = pageTotal || 1;
    const jump = 2;
    let previousPage = page - 1;
    let nextPage = page + 1;
    let start = page - jump;
    let end = page + jump;
    const pages = [];
    const changePage = (page: number) => applyPaging({ ...paging, page: page })

    if (start < firstPage)
        end += start * -1 + 1;

    if (end > lastPage)
        start -= end - lastPage;

    if (start < firstPage)
        start = firstPage;

    if (end > lastPage)
        end = lastPage;

    if (previousPage < firstPage)
        previousPage = firstPage;

    if (nextPage > lastPage)
        nextPage = lastPage;

    for (let p = start; p <= end; p++) {
        const pageItemClasses = classNames("page-item", { active: p === page });

        pages.push(
            <li key={`PageLink${p}`} className={pageItemClasses}>
                <a className="page-link" onClick={() => changePage(p)} href="#">
                    {p === start && p !== firstPage
                        ? "..."
                        : p === end && p !== lastPage
                            ? "..."
                            : p}
                </a>
            </li>
        );
    }

    const firstPageDisabled = page === firstPage;
    const firstPageItemClasses = classNames("page-item", { disabled: firstPageDisabled });

    const lastPageDisabled = page === lastPage;
    const lastPageItemClasses = classNames("page-item", { disabled: lastPageDisabled });

    return (
        <nav aria-label="Table pages">
            <ul className="pagination">
                <li className={firstPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(firstPage)} aria-label="First Page" href={firstPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </span>
                        <span className="sr-only">First</span>
                    </a>
                </li>
                <li className={firstPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(previousPage)} aria-label="Previous Page" href={firstPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>
                {pages}
                <li className={lastPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(nextPage)} aria-label="Next Page" href={lastPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
                <li className={lastPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(lastPage)} aria-label="Last Page" href={lastPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </span>
                        <span className="sr-only">Last</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
}
