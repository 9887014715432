import * as React from 'react';
import { useUniqueId } from '../Hooks/UniqueId';
import { FormGroup } from './FormGroup';

interface FileUploadProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    automationId?: string;
    label?: string;
    placeholder?: string;
    required?: boolean;
    accept?: string;
}

export const FileUpload = ({ automationId, label = '', placeholder = '', disabled = false, required = false, pattern, accept, ...rest }: FileUploadProps) => {

    const uniqueId = useUniqueId();

    const requiredClass = required
        ? 'is-required'
        : null;

    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <div className="custom-file">
                <input id={uniqueId} className={`custom-file-input ${requiredClass}`} type="file" disabled={disabled} required={required} accept={accept} data-automationid={automationId} {...rest} />
                <label className="custom-file-label" htmlFor={uniqueId}>{placeholder}</label>
            </div>
        </FormGroup>
    );
}
