import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

const rootId = 'root-modal';

interface ModalProps {
    automationId?: string;
    visible?: boolean;
    position?: 'centered' | 'top';
    size?: 'sm' | 'md' | 'lg';
    header?: ReactNode;
    footer?: ReactNode;
    children?: ReactNode;
}

const ModalWrapped = ({ automationId, header, footer, position = 'centered', size = 'lg', children }: ModalProps) => {

    useEffect(() => {
        const modalClass = 'modal-open';
        document.body.classList.add(modalClass);

        return () => document.body.classList.remove(modalClass);
    }, []);

    const headerWrapper = header
        ? (<div className="modal-header">{header}</div>)
        : null;

    const footerWrapper = footer
        ? (<div className="modal-footer">{footer}</div>)
        : null;

    return (
        <>
            <div className="modal-backdrop fade show" />
            <div className="modal fade show" tabIndex={-1} role="dialog" style={{ display: "block" }} data-automationid={automationId}>
                <div className={`modal-dialog modal-${size} modal-dialog-${position}`}>
                    <div className="modal-content">
                        {headerWrapper}
                        <div className="modal-body">{children}</div>
                        {footerWrapper}
                    </div>
                </div>
            </div>
        </>
    );
}

export const Modal = ({ visible = true, children, ...rest }: ModalProps) => {

    const root = document.getElementById(rootId);

    return visible && root
        ? createPortal(<ModalWrapped {...rest}>{children}</ModalWrapped>, root)
        : null;
}
