import * as React from 'react';
import * as Informed from 'informed';
import classNames from 'classnames';
import { FormGroup } from './FormGroup';
import { useUniqueId } from '../Hooks/UniqueId';
import { Validator, noValidation } from '../Validators/Validator';
import { validateMany } from '../Validators/Many';
import { validateRequired } from '../Validators/Required';
import { validatePattern } from '../Validators/Pattern';
import strings from '../strings.json';

interface TextProps {
    field: string;
    automationId?: string;
    initialValue?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    required?: boolean;
    pattern?: RegExp;
    onValidate?: Validator;
    appendElement?: React.ReactNode;
    requiredMessage?: string;
    invalidPatternMessage?: string;
}

export const Text = ({ field, automationId, initialValue = '', label = '', placeholder = '', disabled = false, autoFocus = false, required = false, pattern, onValidate = noValidation, appendElement, requiredMessage = strings.required, invalidPatternMessage = strings.invalidFormat }: TextProps) => {

    const uniqueId = useUniqueId();
    const fieldState = Informed.useFieldState(field);

    const handleValidation = validateMany([
        validateRequired(required, requiredMessage),
        validatePattern(pattern, invalidPatternMessage),
        onValidate,
    ]);

    const errorMessage = fieldState.error
        ? (<div className="invalid-feedback">{fieldState.error}</div>)
        : null;

    const invalidClass = fieldState.error
        ? 'is-invalid'
        : '';

    const requiredClass = required
        ? 'is-required'
        : '';

    const inputGroupClass = classNames({ "input-group": appendElement });

    return (
        <FormGroup label={label} htmlFor={uniqueId}>
            <div className={inputGroupClass}>
                <Informed.Text id={uniqueId} className={`form-control ${invalidClass} ${requiredClass}`} field={field} initialValue={initialValue} placeholder={placeholder} disabled={disabled} autoFocus={autoFocus} validate={handleValidation} autoComplete="off" data-automationid={automationId} />
                {appendElement ? <div className="input-group-append">
                    <div className="input-group-text">{appendElement}</div>
                </div> : null}
                {errorMessage}
            </div>
        </FormGroup>
    );
}
