import { useState } from 'react';

const root = "http://localhost:53211/api/v1"

type Status = 'pending' | 'fetching' | 'success' | 'error';
type Request<T> = [() => void, Status, T];
type RequestContent<TRequest, TResponse> = [(request?: TRequest) => void, Status, TResponse];

export interface About {
    machineName: string;
    operatingSystem: string;
    architecture: string;
    framework: string;
    timeZone: string;
    utcOffset: string;
    serviceName: string;
    vendor: string;
    connection: string;
    version: string;
    isUpdatePending: boolean;
    environment: string;
    status: string;
    installationPath: string;
}

const defaultAbout: About = {
    machineName: '',
    operatingSystem: '',
    architecture: '',
    framework: '',
    timeZone: '',
    utcOffset: '',
    serviceName: '',
    vendor: '',
    connection: '',
    version: '',
    isUpdatePending: false,
    environment: '',
    status: '',
    installationPath: '',
}

export const useGetAbout = (): Request<About> => {
    const [response, setResponse] = useState<About>(defaultAbout);
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/about`)
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, response];
}

export interface Device {
    id: string;
    type: string;
    name: string;
    vendor: string;
    hardware: string;
    firmware: string;
    serial: string;
    connection: string;
    status: string;
    commands: { name: string }[];
}

export const useGetDevices = (): Request<Device[]> => {
    const [response, setResponse] = useState<Device[]>([]);
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/devices`)
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => {
                setStatus('error');
                setResponse([]);
            });
    };

    return [init, status, response];
}

interface Log {
    id: string;
    path: string;
    size: number;
    isCurrent: boolean;
    created: string;
    modified: string;
}

export const useGetLogs = (): Request<Log[]> => {
    const [response, setResponse] = useState<Log[]>([]);
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/logs`)
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, response];
}

interface Settings {
    serviceUrl: string;
    allowedOrigins: string;
    environment: string;
    logLevel: string;
    updateUrl: string;
    updatesEnabled: boolean;
}

const defaultSettings : Settings = {
    serviceUrl: '',
    allowedOrigins: '',
    environment: '',
    logLevel: '',
    updateUrl: '',
    updatesEnabled: false,
}

export const useGetSettings = (): Request<Settings> => {
    const [response, setResponse] = useState<Settings>(defaultSettings);
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/settings`)
            .then(response => response.json())
            .then(json => setResponse(json))
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, response];
}

export const usePostDriversInstall = (): Request<null> => {
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/drivers/install`, {
            method: "POST",
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, null];
}

export const usePostSettingsRestore = (): Request<null> => {
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/settings/restore`, {
            method: "POST",
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, null];
}

export const usePostUpdateApply = (): Request<null> => {
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/updates/apply`, {
            method: "POST",
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, null];
}

export const usePostUpdateRestart = (): Request<null> => {
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/updates/restart`, {
            method: "POST",
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, null];
}

export const useGetLogsClear = (): Request<null> => {
    const [status, setStatus] = useState<Status>('pending');

    const init = () => {
        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/logs/clear`)
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, null];
}

export const usePostDeviceCommand = (device: string, command: string): RequestContent<any, any> => {
    const [response, setResponse] = useState<any>();
    const [status, setStatus] = useState<Status>('pending');

    const init = (request: any = { }) => {

        if (status === 'fetching')
            return;

        setStatus('fetching');

        fetch(`${root}/devices/${device}/${command}`, {
            method: "POST",
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(request),
        })
            .then(() => setStatus('success'))
            .catch(() => setStatus('error'));
    };

    return [init, status, response];
}

export const postDeviceCommandNoResponse = (device: string, command: string, body: any = {}): Promise<boolean> => {
    return fetch(`${root}/devices/${device}/${command}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
        .then(response => {
            if (!response.ok)
                return Promise.reject(response);

            return response.ok;
        })
}
