import * as React from 'react';
import { createContext, useContext, ReactElement, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faSort, faFilter, faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from './Query';
import classNames from 'classnames';
import { Button } from './Button';

const RowContext = createContext<any>({});

export const useRow = () => useContext(RowContext);

interface TableProps {
    data: { id: string }[];
    automationId?: string;
    displayFilterWarning?: boolean;
    children?: ReactElement<ColumnProps>[];
}

export const Table = ({ data, automationId, displayFilterWarning = true, children = [] }: TableProps) => {

    const { filters, filter } = useQuery();

    const handleClearFilters = () => filter([]);

    const headers = children.map((child, index) => (
        <th className="text-nowrap" data-automationid={`header${index}`}>{child.props.header}</th>
    ));

    const rows = data.map((value, index) => (
        <RowContext.Provider value={value}>
            <tr data-automationid={`row${index}`}>{children.map((child, index) => <td className="text-nowrap" data-automationid={`column${index}`}>{child}</td>)}</tr>
        </RowContext.Provider>
    ));

    const alert = displayFilterWarning && filters.length > 0
        ? (
            <tr>
                <td className="alert alert-info" colSpan={children.length}>
                    <div className="icon"><FontAwesomeIcon icon={faInfoCircle} /></div>One or more filters are applied to the results. <Button style="link" onClick={handleClearFilters}>Clear filters</Button>
                </td>
            </tr>
        )
        : null;

    return (
        <div className="table-responsive rounded-top">
            <table className="table table-striped table-hover" data-automationid={automationId}>
                <thead className="thead-dark">
                    <tr>{headers}</tr>
                    {alert}
                </thead>
                <tbody>{rows}</tbody>
            </table>
            <TablePagination />
        </div>
    );
}

export interface ColumnProps {
    field?: string;
    header?: ReactNode;
    formatter?: (value: string | number | string[] | number[]) => ReactNode;
}

export const Column = ({ field, formatter = (value) => value }: ColumnProps) => {

    const row = useRow();
    const value = field
        ? row[field]
        : null;
    const formatted = formatter(value);

    return (
        <>{formatted}</>
    );
}

interface FilterHeaderProps {
    sorted?: 'unordered' | 'ascending' | 'descending';
    filtered?: boolean;
    onSort?: () => void;
    onFilter?: () => void;
    children?: ReactNode;
}

export const FilterHeader = ({ sorted = 'unordered', filtered = false, onSort, onFilter, children }: FilterHeaderProps) => {

    const sortIcon = sorted === "unordered"
        ? faSort
        : sorted === "descending"
            ? faSortDown
            : faSortUp;

    const sortedClass = sorted !== 'unordered'
        ? 'active'
        : '';

    const filteredClass = filtered
        ? 'active'
        : '';

    const sort = sorted || onSort
        ? <button data-automationid="sort" className={`action ${sortedClass}`} disabled={!onSort} onClick={onSort}><FontAwesomeIcon icon={sortIcon} /></button>
        : null;

    const filter = filtered || onFilter
        ? <button data-automationid="filter" className={`action ${filteredClass}`} disabled={!onFilter} onClick={onFilter}><FontAwesomeIcon icon={faFilter} /></button>
        : null;

    return (
        <div className="query-header">
            {children}
            {sort}
            {filter}
        </div>
    );
}

const TablePagination = () => {
    const { page, pageTotal, changePage } = useQuery();

    const firstPage = 1;
    const lastPage = pageTotal || 1;
    const jump = 2;
    let previousPage = page - 1;
    let nextPage = page + 1;
    let start = page - jump;
    let end = page + jump;
    const pages = [];

    if (start < firstPage) {
        end += start * -1 + 1;
    }
    if (end > lastPage) {
        start -= end - lastPage;
    }
    if (start < firstPage) {
        start = firstPage;
    }
    if (end > lastPage) {
        end = lastPage;
    }
    if (previousPage < firstPage) {
        previousPage = firstPage;
    }
    if (nextPage > lastPage) {
        nextPage = lastPage;
    }

    for (let p = start; p <= end; p++) {
        const pageItemClasses = classNames("page-item", { active: p === page });

        pages.push(
            <li key={`PageLink${p}`} className={pageItemClasses}>
                <a className="page-link" onClick={() => changePage(p)} href="#">
                    {p === start && p !== firstPage
                        ? "..."
                        : p === end && p !== lastPage
                            ? "..."
                            : p}
                </a>
            </li>
        );
    }

    const firstPageDisabled = page === firstPage;
    const firstPageItemClasses = classNames("page-item", { disabled: firstPageDisabled });

    const lastPageDisabled = page === lastPage;
    const lastPageItemClasses = classNames("page-item", { disabled: lastPageDisabled });

    return (
        <nav aria-label="Table pages">
            <ul className="pagination">
                <li className={firstPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(firstPage)} aria-label="First Page" href={firstPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </span>
                        <span className="sr-only">First</span>
                    </a>
                </li>
                <li className={firstPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(previousPage)} aria-label="Previous Page" href={firstPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>
                {pages}
                <li className={lastPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(nextPage)} aria-label="Next Page" href={lastPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
                <li className={lastPageItemClasses}>
                    <a className="page-link" onClick={() => changePage(lastPage)} aria-label="Last Page" href={lastPageDisabled ? null : "#"}>
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </span>
                        <span className="sr-only">Last</span>
                    </a>
                </li>
            </ul>
        </nav>
    );
}
