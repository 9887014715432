import * as React from "react";
import Select from "react-select";
import { Modal } from "../Modal";
import { useODataQuery } from "./ODataQuery";

interface ODataMatchFilterModalProps {
    title: string;
    field: string;
    isCollectionFilter?: boolean;
    options: any[];
    onClose: () => void;
}

export const ODataMatchFilterModal = ({ title, field, isCollectionFilter = false, options, onClose }: ODataMatchFilterModalProps) => {

    const [values, setValues] = React.useState<any[]>([]);
    const { filters, applyFilters, paging, applyPaging } = useODataQuery();

    React.useEffect(() => {

        const currentValues = filters
            .filter(f => f.field === field && f.condition === 'eq')
            .flatMap(f => f.values);

        setValues(currentValues);
    }, []);

    const handleApply = () => {

        let newFilters = filters.filter(f => f.field !== field);

        if (values && values.length > 0)
            newFilters = [...newFilters, {
                field: field,
                condition: 'eq',
                values: values,
                isCollection: isCollectionFilter,
            }];

        applyPaging({ ...paging, page: 1 });
        applyFilters(newFilters);
        onClose();
    }

    const handleClear = () => {

        applyPaging({ ...paging, page: 1 });
        applyFilters(filters.filter(f => f.field !== field && f.condition !== 'eq'));
        onClose();
    }

    const footer = (
        <div className="modal-footer">
            <button className="btn btn-primary" onClick={handleApply}>OK</button>
            <button className="btn btn-danger" onClick={handleClear}>Clear</button>
        </div>
    )

    return (
        <Modal header={`${title} Filter`} footer={footer}>
            <label>{title}</label>
            <Select
                name="match"
                isMulti={true}
                classNamePrefix="multiSelect"
                options={options}
                autoFocus={true}
                value={values.map(v => options.find(o => o.value === v))}
                getOptionLabel={o => o.label}
                getOptionValue={o => o.value}
                onChange={(o: any[]) => setValues(o.map(v => v.value))}
            />
        </Modal>
    );
};
