import * as React from 'react';
import { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface CardProps {
    header?: ReactNode,
    footer?: ReactNode,
    icon?: IconDefinition,
    children?: ReactNode,
}

export const Card = ({ header, footer, icon, children }: CardProps) => {

    const headerWrapper = header && !icon
        ? (<div className="card-header bg-transparent">{header}</div>)
        : null;

    const bodyWrapper = icon
        ? (
            <div className="row">
                <div className="col-sm-12 col-md-2 text-center">
                    <FontAwesomeIcon icon={icon} size="6x" color="#00aeff" />
                </div>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            {header}
                        </div>
                        <div className="col-12">
                            <hr className="border-light" />
                        </div>
                        <div className="col-12">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
        : children;

    const footerWrapper = footer
        ? (<div className="card-footer text-right">{footer}</div>)
        : null;

    return (
        <div className="card mb-4">
            {headerWrapper}
            <div className="card-body">
                {bodyWrapper}
            </div>
            {footerWrapper}
        </div>
    );
}
