import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faVitaloJak2: IconDefinition = {
    prefix: "fab",
    iconName: "edit",
    icon: [
        100,
        100,
        [],
        "0058",
        "M70.881,84.972L29.119,84.972C26.238,84.972 23.899,82.633 23.899,79.752L23.899,20.847C23.899,17.966 26.238,15.627 29.119,15.627L70.881,15.627C73.762,15.627 76.101,17.966 76.101,20.847L76.101,79.752C76.101,82.633 73.762,84.972 70.881,84.972ZM74.557,75.349L25.427,75.349L25.427,78.311C25.427,81.022 27.628,83.224 30.34,83.224L69.644,83.224C72.355,83.224 74.557,81.022 74.557,78.311L74.557,75.349ZM38.772,54.688L32.965,54.688C32.564,54.689 32.239,55.014 32.239,55.414L32.239,61.221C32.239,61.622 32.564,61.947 32.965,61.947L38.772,61.947C39.173,61.947 39.498,61.622 39.498,61.221L39.498,55.414C39.498,55.014 39.173,54.689 38.772,54.688ZM48.295,54.688L42.488,54.688C42.087,54.689 41.762,55.014 41.762,55.414L41.762,61.221C41.762,61.622 42.087,61.947 42.488,61.947L48.295,61.947C48.695,61.947 49.021,61.622 49.021,61.221L49.021,55.414C49.021,55.014 48.695,54.689 48.295,54.688ZM57.569,54.688L51.762,54.688C51.361,54.689 51.036,55.014 51.036,55.414L51.036,61.221C51.036,61.622 51.361,61.947 51.762,61.947L57.569,61.947C57.97,61.947 58.295,61.622 58.295,61.221L58.295,55.414C58.295,55.014 57.97,54.689 57.569,54.688ZM66.99,54.688L61.183,54.688C60.782,54.688 60.457,55.014 60.457,55.414L60.457,61.221C60.457,61.622 60.782,61.947 61.183,61.947L66.99,61.947C67.391,61.947 67.716,61.622 67.716,61.221L67.716,55.414C67.716,55.014 67.391,54.688 66.99,54.688ZM60.139,32.075L39.845,32.075C38.839,32.075 38.022,32.892 38.022,33.898L38.022,48.477C38.022,49.483 38.839,50.3 39.845,50.3L60.139,50.3C61.145,50.3 61.961,49.483 61.961,48.477L61.961,33.898C61.961,32.892 61.145,32.075 60.139,32.075ZM35.698,28.798L31.019,28.798C30.925,28.799 30.849,28.875 30.849,28.968L30.849,30.327C30.849,30.421 30.925,30.497 31.019,30.497L35.698,30.497C35.792,30.497 35.868,30.421 35.868,30.327L35.868,28.968C35.868,28.875 35.792,28.799 35.698,28.798Z"
    ]
}
