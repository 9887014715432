import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faSvc: IconDefinition = {
    prefix: "fab",
    iconName: "edit",
    icon: [
        48,
        38,
        [],
        "0063",
        "M2.17,9.314L0,9.314L0,0.154L2.17,0.154L2.17,9.314ZM2.17,10.314L0,10.314L0,18.734L2.17,18.734L2.17,10.314ZM2.17,19.734L0,19.734L0,28.154L2.17,28.154L2.17,19.734ZM2.17,29.154L0,29.154L0,37.564L2.17,37.564L2.17,29.154ZM9.74,37.564L9.74,35.404L0,35.404L0,37.564L9.74,37.564ZM19.1,37.564L19.1,35.404L10.95,35.404L10.95,37.564L19.1,37.564ZM28.52,37.564L28.52,35.404L20.37,35.404L20.37,37.564L28.52,37.564ZM37.94,37.564L37.94,35.404L29.79,35.404L29.79,37.564L37.94,37.564ZM47.58,37.564L47.58,35.404L39.44,35.404L39.44,37.564L47.58,37.564ZM15.42,16.234L7.08,16.234L7.08,33.814L15.42,33.814L15.42,16.234ZM24.92,10.314L16.58,10.314L16.58,33.814L24.92,33.814L24.92,10.314ZM34.33,0.524L26,0.524L26,33.814L34.33,33.814L34.33,0.524ZM43.75,14.984L35.42,14.984L35.42,33.814L43.75,33.814L43.75,14.984ZM12.83,7.804L15.58,0.134L14.03,0.134L12.88,3.634C12.57,4.574 12.27,5.504 12.06,6.414C11.86,5.494 11.58,4.594 11.28,3.624L10.19,0.134L8.6,0.134L11.18,7.804L12.83,7.804ZM21.83,6.474C21.324,6.657 20.789,6.749 20.25,6.744C20.17,6.752 20.09,6.755 20.01,6.755C18.611,6.755 17.46,5.604 17.46,4.205C17.46,4.131 17.464,4.058 17.47,3.984C17.463,3.908 17.46,3.832 17.46,3.756C17.46,2.33 18.634,1.156 20.06,1.156C20.127,1.156 20.193,1.159 20.26,1.164C20.795,1.159 21.325,1.261 21.82,1.464L22.13,0.344C21.517,0.1 20.859,-0.013 20.2,0.014C20.09,0.005 19.98,0 19.869,0C17.708,0 15.929,1.779 15.929,3.94C15.929,3.965 15.93,3.989 15.93,4.014C15.93,6.354 17.47,7.874 20,7.874C20.706,7.896 21.409,7.781 22.07,7.534L21.83,6.474Z"
    ]
}
