import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const faFvc: IconDefinition = {
    prefix: "fab",
    iconName: "edit",
    icon: [
        104,
        82,
        [],
        "0061",
        "M83.400003,77.200001 L103.8,77.200001 103.8,81.799999 83.400003,81.799999 z M63.1,77.200001 L81.699999,77.200001 81.699999,81.799999 63.1,81.799999 z M42.1,77.200001 L61.000002,77.200001 61.000002,81.799999 42.1,81.799999 z M21.5,77.200001 L40.4,77.200001 40.4,81.799999 21.5,81.799999 z M0,61.700001 L4.6000004,61.700001 4.6000004,77.200001 19.4,77.200001 19.4,81.799999 0,81.799999 0,81.600002 0,77.200001 z M0,40.799999 L4.6000004,40.799999 4.6000004,59.700001 0,59.700001 z M0,20.400002 L4.6000004,20.400002 4.6000004,39 0,39 z M103.4,15.700001 L103.8,23.400002 41.1,26.799999 C34.699999,27 29.6,29.400002 25.6,33.900002 15,45.900002 15.700001,69.500004 16,75.200001 L12.1,75.200001 8.3000011,75.200001 C7.8999996,68.000004 7.5,42.799999 19.9,28.900002 25.300001,22.799999 32.4,19.5 40.9,19.200001 z M8.8999996,0.40000153 L19.4,0.40000153 19.4,3 12,3 12,6.6000004 18.4,6.6000004 18.4,9.2000008 12,9.2000008 12,15.700001 8.8999996,15.700001 8.8999996,15.600002 z M20.4,0.30000114 L23.699999,0.30000114 27.6,11.6 31.300001,0.30000114 34.6,0.30000114 29.1,15.600002 25.800001,15.600002 z M0,0.10000038 L4.6000004,0.10000038 4.6000004,18.299999 0,18.299999 z M42.699999,0 C44.6,0 46.1,0.60000038 47.199999,1.7000008 47.9,2.4000015 48.4,3.3000011 48.800001,4.5 L45.800001,5.2000008 C45.6,4.4000015 45.199999,3.8000011 44.699999,3.3000011 44.1,2.8000011 43.4,2.6000004 42.6,2.6000004 41.500002,2.6000004 40.6,3 39.800001,3.8000011 39.1,4.6000004 38.699999,5.9000015 38.699999,7.7000008 38.699999,9.6000004 39.000002,11 39.699999,11.800001 40.4,12.6 41.300001,13 42.4,13 43.199999,13 43.9,12.700001 44.500002,12.200001 45.199999,11.900002 45.6,11.1 45.9,10 L48.9,10.900002 C48.4,12.6 47.699999,13.800001 46.6,14.6 45.500002,15.400002 44.199999,15.799999 42.500002,15.799999 40.500002,15.799999 38.800001,15.1 37.500002,13.700001 36.199999,12.300001 35.500002,10.400002 35.500002,8 35.500002,5.5 36.199999,3.5 37.500002,2.1000004 38.800001,0.70000076 40.500002,0 42.699999,0 z"
    ]
}
