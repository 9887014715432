import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Navbar } from './Components/Ui';
import { NotFound, Troubleshoot, Dashboard, Logs, Settings } from './Components/Views';
import { AuthProvider } from 'oidc-react';
import { DeviceLink } from './Components/Hooks/DeviceLink';
import { DeviceLinkDashboard, useDeviceLinkDashboard } from './Components/Hooks/DeviceLinkDashboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const App = () => {

    const { replace } = useHistory();
    const { online, about } = useDeviceLinkDashboard();

    const handleSignIn = () => replace('/');

    if (!online)
        return (
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 text-center p-4 text-primary">
                        <FontAwesomeIcon icon={faCircleNotch} size="10x" spin />
                    </div>
                    <div className="col-12 text-center">
                        <h2>Connecting</h2>
                        <p>Loading the dashboard</p>
                    </div>
                </div>
            </div>
        );

    return (
        <AuthProvider authority={about.oAuthAddress} clientId={about.oAuthClientId} scope={about.oAuthScopes} redirectUri={about.oAuthRedirect} automaticSilentRenew={true} autoSignIn={false} loadUserInfo={true} onSignIn={handleSignIn}>
            <DeviceLink>
                <div className="bg-primary mb-4">
                    <div className="container-fluid">
                        <Navbar />
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-6">
                            <Switch>
                                <Route exact path="/"><Dashboard /></Route>
                                <Route exact strict path="/troubleshoot/:label?"><Troubleshoot /></Route>
                                <Route exact path="/logs"><Logs /></Route>
                                <Route exact path="/settings"><Settings /></Route>
                                <Route><NotFound /></Route>
                            </Switch>
                        </div>
                    </div>
                    <footer>
                        <p className="text-center text-secondary no-select"><small>&copy; {about.service} {about.version}</small></p>
                    </footer>
                </div>
            </DeviceLink>
        </AuthProvider>
    );
}

const Router = () => {

    return (
        <BrowserRouter>
            <DeviceLinkDashboard>
                <App />
            </DeviceLinkDashboard>
        </BrowserRouter>
    );
}

ReactDOM.render(<Router />, document.getElementById('root-app'));
