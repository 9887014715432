import * as React from 'react';
import { ReactNode, MouseEvent } from 'react';

interface ButtonProps {
    automationId?: string;
    type?: 'button' | 'submit' | 'reset';
    style?: 'primary' | 'success' | 'warning' | 'danger' | 'link';
    disabled?: boolean;
    onClick?: (e: MouseEvent) => void;
    children?: ReactNode;
}

export const Button = ({ automationId, type = 'button', style = 'primary', disabled = false, onClick = () => { }, children }: ButtonProps) => (
    <button className={`btn btn-${style}`} type={type} disabled={disabled} formNoValidate={true} onClick={onClick} data-automationid={automationId}>{children}</button>
)
