import { useState, useEffect } from 'react';
import * as Cookies from 'js-cookie';

export const useSessionCookie = (key: string): [string | undefined, (value: string) => void] => {

    const [cookie, setCookie] = useState(() => Cookies.get(key));

    useEffect(() => {
        if (cookie)
            return Cookies.set(key, cookie);

        Cookies.remove(key);
    }, [cookie])

    return [cookie, setCookie];
}
