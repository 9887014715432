import * as React from 'react';
import { ReactNode } from 'react';

interface Props {
    style?: 'primary' | 'success' | 'warning' | 'danger';
    children?: ReactNode;
}

export const Badge = ({ style = 'primary', children }: Props) => (
    <span className={`badge badge-${style} badge-pill`}>{children}</span>
)
