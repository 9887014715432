import * as React from "react";
import { useState, createContext, useContext, ReactNode, useEffect } from 'react';
import moment from "moment";
import { validate } from 'uuid';

const DefaultPaging: Paging = {
    page: 1,
    size: 10,
}

const DefaultRefresh = async () => {
    return {
        data: [] as any[],
        '@odata.count': 0,
    }
}

export type ODataCondition = 'eq' | 'ne' | 'gt' | 'ge' | 'lt' | 'le';

export type ODataDirection = 'asc' | 'desc';

interface Filter {
    field: string;
    condition: ODataCondition;
    values: any[];
    isCollection: boolean;
}

export interface Order {
    field: string;
    condition: ODataDirection;
}

interface Paging {
    page: number;
    size: number;
}

export interface ODataParams {
    filter: string;
    orderby: string;
    top: number;
    skip: number;
}

interface QueryState {
    filters: Filter[];
    ordering: Order[];
    paging: Paging;
    count: number;
    data: any[];
    applyFilters: (filters: Filter[]) => void;
    applyOrdering: (orders: Order[]) => void;
    applyPaging: (paging: Paging) => void;
    refresh: () => void;
    refreshing: boolean;
}

const QueryContext = createContext<QueryState>({
    filters: [],
    ordering: [],
    paging: DefaultPaging,
    count: 0,
    data: [],
    applyFilters: () => { },
    applyOrdering: () => { },
    applyPaging: () => { },
    refresh: () => { },
    refreshing: false,
});

const QueryProvider = QueryContext.Provider;

export const useODataQuery = () => useContext(QueryContext);

interface QueryProps {
    initialFilters?: Filter[];
    initialOrdering?: Order[];
    initialPaging?: Paging;
    onRefresh?: (params: ODataParams) => Promise<any>;
    children?: ReactNode;
}

export const ODataQuery = ({ initialFilters = [], initialOrdering = [], initialPaging = DefaultPaging, onRefresh = DefaultRefresh, children }: QueryProps) => {

    const [filters, setFilters] = useState(initialFilters);
    const [ordering, setOrdering] = useState(initialOrdering);
    const [paging, setPaging] = useState(initialPaging);
    const [count, setCount] = useState(0);
    const [data, setData] = useState<any[]>([]);
    const [refreshing, setRefreshing] = useState(true);

    useEffect(() => {
        handleRefresh();
    }, [filters, ordering, paging]);

    const handleRefresh = async () => {

        setRefreshing(true);

        const { page, size } = paging;
        const skip = size * (page - 1);

        const sanctifyValue = (x: any): any => {
            // Guids (uuid), number and Boolean types shouldn't be wrapped in quotes
            return validate(x) || typeof x === "boolean" || typeof x === "number" || moment(x, "YYYY-MM-DD", true).isValid() ? x : `'${x}'`;          
        }

        const singleFilter = (f: Filter, x: any) => {
            return `${f.field} ${f.condition} ${sanctifyValue(x)}`
        }

        const collectionFilter = (f: Filter, x: any) => {
            const splitFieldParts = f.field.split("/");

            return `${splitFieldParts[0]}/any(x:x/${splitFieldParts[1]} ${f.condition} ${sanctifyValue(x)})`
        }

        const filter = filters.map(f => `(${f.values.map(x => f.isCollection ? collectionFilter(f, x) : singleFilter(f, x))
            .join(' or ')})`)
            .join(' and ');

        const order = ordering.map(o => `${o.field} ${o.condition}`).join(', ');
        const params: ODataParams = {
            filter: filter.length === 0
                ? undefined
                : filter,
            orderby: order.length === 0
                ? undefined
                : order,
            top: size,
            skip: skip,
        }

        const result = await onRefresh(params);
        setCount(result['@odata.count']);
        setData(result.value);
        setRefreshing(false);
    }

    const state: QueryState = {
        filters: filters,
        ordering: ordering,
        paging: paging,
        count: count,
        data: data,
        applyFilters: setFilters,
        applyOrdering: setOrdering,
        applyPaging: setPaging,
        refresh: handleRefresh,
        refreshing: refreshing,
    }

    return (
        <QueryProvider value={state}>
            {children}
        </QueryProvider>
    );
}
