import * as React from 'react';
import { useState, useEffect } from 'react';
import { TroubleshootStepProps, TroubleshootStep } from './Troubleshoot';
import { Button, Text, Select } from '@vitalograph/web-ui';
import { Form, useFormApi } from 'informed';
import { useGetCountries } from '../../Api/DashboardApi';

const UserDetailsStepForm = ({ visible, details, onChangeStep, onDetailsChange }: TroubleshootStepProps) => {
    if (!visible)
        return null;


    const { organization, address, siteNumber, countryId, contactName } = details;
    const [ initCountries, countriesStatus, countries ] = useGetCountries();
    const form = useFormApi();

    useEffect(() => {
        initCountries();
    }, [])

    useEffect(() => {
        if (countriesStatus !== 'success')
            return;

        form.setValue('countryId', countryId);
    }, [countriesStatus]);

    const handleValidate = (value: string) => value ? undefined : "Required.";
    const handleBackClick = () => {
        onDetailsChange({ ...details, ...form.getState().values });
        onChangeStep(TroubleshootStep.TermsAndConditions);
    }
    const handleNextClick = () => {
        onDetailsChange({ ...details, ...form.getState().values });
        form.validate();

        if (form.getState().invalid)
            return;

        onChangeStep(TroubleshootStep.SystemDetails);
    }

    const options = countries.map(c => { return { value: c.id, label: c.name } });
    

    if (options === null || options === undefined || options.length === 0)
        return null;

    return (
        <>
            <div className="card-body">
                <Text field="organization" label="Organization" required={true} initialValue={organization} autoFocus={true} />
                <Text field="address" label="Address" required={true} initialValue={address} />
                <Text field="siteNumber" label="Site Number" initialValue={siteNumber} />
                <Select field="countryId" label="Country" options={options} required={true} />
                <Text field="contactName" label="Primary Contact" required={true} initialValue={contactName} />
            </div>
            <div className="card-footer text-right">
                <Button style="primary" onClick={handleBackClick}>Back</Button>
                &nbsp;&nbsp;&nbsp;
                <Button style="success" onClick={handleNextClick}>Next</Button>
            </div>
        </>
    );
}

export const UserDetailsStep = ({ visible, details, onChangeStep, onDetailsChange }: TroubleshootStepProps) =>
    <Form>
        <UserDetailsStepForm visible={visible} details={details} onChangeStep={onChangeStep} onDetailsChange={onDetailsChange} />
    </Form>
