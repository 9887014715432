import * as React from 'react';
import { ReactNode } from 'react';

const defaultTitle = '';

interface TitleProps {
    title?: string;
    children?: ReactNode;
}

export const Title = ({ title = defaultTitle, children }: TitleProps) => {

    React.useEffect(() => {
        document.title = title;

        return () => document.title = defaultTitle;
    }, [title]);

    return (
        <>
            {children}
        </>
    );
}
