import * as React from 'react';
import { faCircleNotch, faExclamationCircle, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Button, Card } from '@vitalograph/web-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePostDriversInstall, usePostUpdateApply, usePostUpdateRestart } from '../../Api/DeviceLinkApi';

interface AboutCardProps {
    version: string;
    machineName: string;
    operatingSystem: string;
    framework: string;
    architecture: string;
    timeZone: string;
    environment: string;
    updates: boolean;
    utcOffset: string;
}

const productionEnvironment = 'Production';

const warningIcon = (
    <FontAwesomeIcon className="text-warning" icon={faExclamationCircle} />
);
const waitIcon = (
    <FontAwesomeIcon icon={faCircleNotch} size="1x" spin />
);

export const AboutCard = ({version, machineName, operatingSystem, framework, architecture, timeZone, environment, updates, utcOffset}: AboutCardProps) => {

    const [initDriverInstall, driverInstallStatus] = usePostDriversInstall();
    const [initUpdate, updateStatus] = usePostUpdateApply();
    const [initRestart, restartStatus] = usePostUpdateRestart();

    const busy = [driverInstallStatus, updateStatus, restartStatus].some(s => s === 'fetching');
    const versionIcon = updates
        ? warningIcon
        : null;
    const busyIcon = busy
        ? waitIcon
        : null;
    const environmentIcon = environment !== productionEnvironment
        ? warningIcon
        : null;

    const updateButton = updates
        ? (
            <div className="btn-group ml-2">
                <Button style="warning" disabled={busy} onClick={initUpdate}>Update</Button>
            </div>
        )
        : null;

    const header = (
        <h4 className="m-0 text-primary">Device Link</h4>
    );

    const footer = (
        <>
            {busyIcon}
            {updateButton}
            <div className="btn-group ml-2">
                <Button disabled={busy} onClick={initRestart}>Restart</Button>
            </div>
        </>
    );

    return (
        <Card icon={faGlobe} header={header} footer={footer}>
            <table className="table table-borderless table-sm">
                <tbody>
                    <tr>
                        <th>Version</th>
                        <td>{version} {versionIcon}</td>
                    </tr>
                    <tr>
                        <th>Machine</th>
                        <td>{machineName}</td>
                    </tr>
                    <tr>
                        <th>OS</th>
                        <td>{operatingSystem}</td>
                    </tr>
                    <tr>
                        <th>Framework</th>
                        <td>{framework}</td>
                    </tr>
                    <tr>
                        <th>Architecture</th>
                        <td>{architecture}</td>
                    </tr>
                    <tr>
                        <th>Time Zone</th>
                        <td>{timeZone} ({utcOffset})</td>
                    </tr>
                    <tr>
                        <th>Environment</th>
                        <td>{environment} {environmentIcon}</td>
                    </tr>
                    <tr>
                        <th>Drivers</th>
                        <td><Button disabled={busy} onClick={initDriverInstall}>Reinstall</Button></td>
                    </tr>
                </tbody>
            </table>
        </Card>
    );
}
