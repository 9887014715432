import * as React from 'react';
import { useState, createContext, useContext, ReactNode, useEffect } from 'react';
import { About, Device, useGetAbout, useGetDevices } from '../Api/DeviceLinkApi';

interface DeviceLinkState {
    online: boolean;
    about: About;
    devices: Device[];
}

interface DeviceLinkProps {
    refreshInterval?: number;
    children?: ReactNode;
}

const defaultRefreshInterval = 2000;
const defaultDeviceLinkState = {
    online: false,
    about: {
        machineName: '',
        operatingSystem: '',
        architecture: '',
        framework: '',
        timeZone: '',
        utcOffset: '',
        serviceName: '',
        vendor: '',
        connection: '',
        version: '',
        isUpdatePending: false,
        environment: '',
        status: '',
        installationPath: '',
    },
    devices: [],
}

const deviceLinkContext = createContext<DeviceLinkState>(defaultDeviceLinkState);
const DeviceLinkProvider = deviceLinkContext.Provider;

export const useDeviceLink = () => useContext(deviceLinkContext);

export const DeviceLink = ({ refreshInterval = defaultRefreshInterval, children }: DeviceLinkProps) => {

    const [initAbout, aboutStatus, about] = useGetAbout();
    const [initDevices, , devices] = useGetDevices();
    const [online, setOnline] = useState(false);

    const refreshAbout = () => {

        initAbout();
        initDevices();
        setTimeout(refreshAbout, refreshInterval);
    }

    useEffect(refreshAbout, [refreshInterval]);

    useEffect(() => {

        if (aboutStatus === 'success')
            setOnline(true);
        else if (aboutStatus === 'error')
            setOnline(false);
    }, [aboutStatus]);

    return (
        <DeviceLinkProvider value={{ online: online, about: about, devices: devices }}>
            {children}
        </DeviceLinkProvider>
    );
}
