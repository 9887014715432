import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

interface LoadingProps {
    message: string;
    size?: SizeProp;
}

export const Loading = ({ message, size = "6x" }: LoadingProps) => {
    return (
        <div className="row">
            <div className="col">
                <div className="col text-center pt-2 pb-2 text-primary">
                    <FontAwesomeIcon icon={faCircleNotch} size={size} spin />
                    <h5 className="mt-2">{message}</h5>
                </div>
            </div>
        </div>
    );
}
